/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

const Bio = props => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic-felipe.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata

  const bioTextMapping = {
    en: (
      <>
        <p>
          Blog by <strong>{author}</strong> who lives and works in Rio de
          Janeiro building useful things. For my social networks, access{" "}
          <Link className="text-blue-500" to="/about-me">
            my about page
          </Link>
          .
        </p>
        <p className="mt-3">
          I work at Carta and we are hiring for a lot of different positions,
          with offices in Brazil, US, Canada and Singapore.{" "}
          <a
            className="text-blue-500"
            target="_blank"
            rel="noreferrer"
            href="https://grnh.se/9b14bf663us"
          >
            Take a look here
          </a>
        </p>
      </>
    ),
    pt: (
      <>
        <p>
          Blog feito por <strong>{author}</strong>, que vive e trabalho no Rio
          de Janeiro criando coisas legais. Para minha redes sociais e contatos,
          visite{" "}
          <Link className="text-blue-500" to="/pt/about-me">
            a sessão sobre mim
          </Link>
          .
        </p>
        <p className="mt-3">
          Eu trabalho na Carta e estamos contratando para diferentes vagas, com
          escritórios no Brasil, EUA, Canadá e Singapura.{" "}
          <a
            className="text-blue-500"
            target="_blank"
            rel="noreferrer"
            href="https://grnh.se/9b14bf663us"
          >
            Você pode dar uma olhada aqui.
          </a>
        </p>
      </>
    ),
  }

  return (
    <div className="flex items-center">
      <div className="w-2/12 inline-block align-middle">
        <Image
          className="rounded-full"
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
        />
      </div>
      <div className="w-10/12 inline-block">
        <p>{bioTextMapping[props.currentLangKey]}</p>
      </div>
    </div>
  )
}

export default Bio
