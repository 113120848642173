import React from "react"
import { Link, graphql } from "gatsby"

import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

class BookPageTemplate extends React.Component {
  render() {
    const bookPage = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    const {
      langs,
      defaultLangKey,
    } = this.props.data.site.siteMetadata.languages
    const url = typeof window !== "undefined" ? window.location.pathname : ""
    const langKey = getCurrentLangKey(langs, defaultLangKey, url)

    const tableOfContentsLangMapping = {
      en: "Table of contents",
      pt: "Índice",
    }

    const {
      previousBookPageLink,
      previousBookPageTitle,
      nextBookPageLink,
      nextBookPageTitle,
    } = bookPage.frontmatter

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={bookPage.frontmatter.title}
          description={bookPage.frontmatter.description || bookPage.excerpt}
        />
        <div className="container w-11/12 lg:w-7/12">
          <article>
            <header className="mb-8">
              <h1 className="mt-2 mb-0 text-3xl font-bold">
                {bookPage.frontmatter.title}
              </h1>
            </header>
            <section
              className="blog-post"
              dangerouslySetInnerHTML={{ __html: bookPage.html }}
            />
            <hr className="mb-2 mt-4" />
          </article>

          <nav className="mb-8 mt-8">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li className="flex-1">
                {previousBookPageLink && (
                  <Link to={previousBookPageLink} rel="prev">
                    ← {previousBookPageTitle}
                  </Link>
                )}
              </li>
              <li className="flex-1 text-center">
                <Link to="../" rel="prev">
                  {tableOfContentsLangMapping[langKey]}
                </Link>
              </li>
              <li className="flex-1 text-right">
                {nextBookPageLink && (
                  <Link to={nextBookPageLink} rel="next">
                    {nextBookPageTitle} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </Layout>
    )
  }
}

export default BookPageTemplate

export const pageQuery = graphql`
  query BookPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        languages {
          defaultLangKey
          langs
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        previousBookPageLink
        previousBookPageTitle
        nextBookPageLink
        nextBookPageTitle
      }
    }
  }
`
